import * as React from "react";

import { input } from "./SearchInput.module.scss";

interface SearchInputProps {
  onChange: (value: string) => void;
  delay?: number;
}

export function SearchInput(props: SearchInputProps) {
  const { onChange, delay } = props;

  const timeout = React.useRef<NodeJS.Timeout>();

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        if (delay) {
          if (timeout.current) clearTimeout(timeout.current);
          timeout.current = setTimeout(() => {
            onChange(e.target.value);
          }, delay);
        } else onChange(e.target.value);
      }
    },
    [onChange, delay]
  );

  return (
    <input placeholder="Search..." className={input} onChange={handleChange} />
  );
}
